import { getBreakpoint } from '@pretto/bricks/assets/utility'
import { useNavigationLink } from '@pretto/bricks/components/utility/NavigationLinkProvider'

import PropTypes from 'prop-types'
import qs from 'qs'
import { forwardRef, memo } from 'react'
import url from 'url'

const HEADER_HEIGHT_MOBILE = 56
const HEADER_HEIGHT_DESKTOP = 88
const SPACING_MOBILE = 16
const SPACING_DESKTOP = 24

const Link = forwardRef(({ children, href, ...props }, ref) => {
  const { linkComponent: LinkComponent, location, persistingSearchParams = [] } = useNavigationLink()

  const handleAnchor = e => {
    const anchor = document.getElementById(href.replace('#', ''))

    if (anchor) {
      e.preventDefault()
      const { isMobile } = getBreakpoint()

      const offsetHeader = isMobile ? HEADER_HEIGHT_MOBILE + SPACING_MOBILE : HEADER_HEIGHT_DESKTOP + SPACING_DESKTOP
      const top = anchor.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offsetHeader

      if (window.scrollTo)
        window.scrollTo({
          behavior: 'smooth',
          top,
        })
      else document.querySelector(href).scrollIntoView()
    }
  }

  if (href.indexOf('https://app.staging.pretto.fr') === 0) {
    return (
      <a href={href} {...props} ref={ref}>
        {children}
      </a>
    )
  }

  if (!/^\//.test(href)) {
    const linkProps = {
      ...props,
      href,
    }
    if (!href.startsWith('#')) Object.assign(linkProps, { rel: 'noreferrer noopener', target: '_blank' })
    else Object.assign(linkProps, { onClick: handleAnchor })
    return (
      <a {...linkProps} ref={ref}>
        {children}
      </a>
    )
  }

  if (!Link) {
    return (
      <a href={href} {...props} ref={ref}>
        {children}
      </a>
    )
  }

  const { pathname: targetPathname, search: targetSeach, ...targetLocation } = url.parse(href)

  const persistedSearchParams = Object.fromEntries(
    Object.entries(qs.parse(location?.search, { ignoreQueryPrefix: true })).filter(([key]) =>
      persistingSearchParams.includes(key)
    )
  )

  const targetSearchParams = qs.parse(targetSeach, { ignoreQueryPrefix: true })

  const targetHref = url.format({
    ...targetLocation,
    pathname: targetPathname,
    search: qs.stringify({ ...persistedSearchParams, ...targetSearchParams }),
  })

  return (
    <LinkComponent {...props} href={targetHref} ref={ref}>
      {children}
    </LinkComponent>
  )
})

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

Link.displayName = 'Link'

export default memo(Link)
